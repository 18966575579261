<template>
  <div class="instructor-dashboard">
    <div class="instructor-bg">
      <div class="container p-0 position-relative" style="top: 60%">
        <p class="w-100 text-left row px-3 px-md-0 justify-content-between m-0">
          <span class="kallisto-bold font-md-32 font-20 col-8 p-0"
            >Dashboard</span
          >
        </p>
      </div>
    </div>
    <div class="container p-0">
      <div class="instructor-data-table mt-75">
        <v-card>
          <v-card-title class="p-0 mb-26 d-flex justify-content-start">
            <div
              class="cursor-pointer d-flex align-items-center px-3 ml-3 mx-md-0"
              style="
                background: #212121;
                width: 216px !important;
                height: 39px !important;
                border-radius: 4px;
              "
            >
              <img
                class="search-dropdown-icon"
                :src="getIcon('search-solid.svg')"
                height="15"
                alt="search role"
              />
              <input
                type="text"
                v-model="search"
                id="search-field"
                placeholder="Search"
                class="pl-3 text-light font-14 w-100 h-100"
              />
            </div>
            <div
              v-if="this.$store.state.whiteListedUserData.length > 0"
              class="my-0 d-flex pl-3 text-light text-left"
            >
              <button
                class="filter-button-mobile font-14 font-weight-bold"
                data-toggle="modal"
                data-target="#desktopFilterModal"
                style="width: 160px; height: 39px"
              >
                <img
                  :src="getIcon('funnel-mobile.svg')"
                  alt="funnel-mobile"
                  class="pr-1"
                />
                Course filters
              </button>

              <div
                class="modal fade p-0 text-light"
                id="desktopFilterModal"
                tabindex="-1"
                role="dialog"
                aria-hidden="true"
              >
                <div
                  class="modal-dialog modal-md modal-dialog-centered"
                  role="document"
                  style=""
                >
                  <div
                    class="modal-content"
                    style="
                      border: 0.5px solid #0b5786 !important;
                      box-shadow: 0px 3px 14px 0px rgba(184, 236, 255, 0.15);
                      border-radius: 12px;
                      min-width: 520px !important;
                    "
                  >
                    <div
                      class="b-modal-header h-100 d-flex flex-column justify-content-between"
                    >
                      <div class="d-block">
                        <div
                          class="d-flex justify-content-between align-items-center py-3 px-4"
                          style="border-bottom: 0.5px solid #555555"
                        >
                          <span
                            class="d-flex filter-header-mobile kallisto-bold justify-content-center flex-grow-1"
                            style="color: #d0d0d0 !important"
                          >
                            Select filters
                          </span>
                          <a data-dismiss="modal" class="">
                            <img
                              height="12"
                              class="pointer x-button"
                              :src="getIcon('x-button-mobile.svg')"
                              alt="x-button-mobile"
                            />
                          </a>
                        </div>
                        <div id="filter-collapse-mobile" class="mt-24 pb-3">
                          <div class="mb-0 px-4">
                            <div
                              class="mb-3"
                              style="border-bottom: 0.5px solid #ffffff66"
                            >
                              <div class="pb-3">Semester</div>
                              <div
                                id="pod-collapse-mobile"
                                class="collapse show"
                              >
                                <div class="py-1 container">
                                  <div
                                    class="mb-2 row mt-0"
                                    v-for="(pod, po) in Math.ceil(
                                      getSemesters(
                                        $store.state.whiteListedUserData
                                      ).length / 2
                                    )"
                                    :key="po"
                                  >
                                    <div class="col-6 p-0">
                                      <label class="check-container">
                                        <input
                                          :id="
                                            getSemesters(
                                              $store.state.whiteListedUserData
                                            )[po]
                                          "
                                          type="checkbox"
                                          :checked="
                                            selectedSemesters.includes(
                                              getSemesters(
                                                $store.state.whiteListedUserData
                                              )[po]
                                            )
                                          "
                                          @change="
                                            updateSemester(
                                              getSemesters(
                                                $store.state.whiteListedUserData
                                              )[po]
                                            )
                                          "
                                        />
                                        <span
                                          class="checkmark-mobile-dashboard"
                                        ></span>
                                        <span>
                                          <span
                                            class="uppercase-text os-semibold"
                                            style="
                                              text-transform: uppercase !important;
                                            "
                                            >{{
                                              getSemesters(
                                                $store.state.whiteListedUserData
                                              )[po]
                                            }}</span
                                          ></span
                                        >
                                      </label>
                                    </div>
                                    <div
                                      class="col-6 p-0"
                                      v-if="
                                        po +
                                          Math.ceil(
                                            getSemesters(
                                              $store.state.whiteListedUserData
                                            ).length / 2
                                          ) <
                                        getSemesters(
                                          $store.state.whiteListedUserData
                                        ).length
                                      "
                                    >
                                      <label class="check-container">
                                        <input
                                          :id="
                                            getSemesters(
                                              $store.state.whiteListedUserData
                                            )[
                                              po +
                                                Math.ceil(
                                                  getSemesters(
                                                    $store.state
                                                      .whiteListedUserData
                                                  ).length / 2
                                                )
                                            ]
                                          "
                                          type="checkbox"
                                          :checked="
                                            selectedSemesters.includes(
                                              getSemesters(
                                                $store.state.whiteListedUserData
                                              )[
                                                po +
                                                  Math.ceil(
                                                    getSemesters(
                                                      $store.state
                                                        .whiteListedUserData
                                                    ).length / 2
                                                  )
                                              ]
                                            )
                                          "
                                          @change="
                                            updateSemester(
                                              getSemesters(
                                                $store.state.whiteListedUserData
                                              )[
                                                po +
                                                  Math.ceil(
                                                    getSemesters(
                                                      $store.state
                                                        .whiteListedUserData
                                                    ).length / 2
                                                  )
                                              ]
                                            )
                                          "
                                        />
                                        <span
                                          class="checkmark-mobile-dashboard"
                                        ></span>
                                        <span>
                                          <span
                                            class="uppercase-text os-semibold"
                                            style="
                                              text-transform: uppercase !important;
                                            "
                                            >{{
                                              getSemesters(
                                                $store.state.whiteListedUserData
                                              )[
                                                po +
                                                  Math.ceil(
                                                    getSemesters(
                                                      $store.state
                                                        .whiteListedUserData
                                                    ).length / 2
                                                  )
                                              ]
                                            }}</span
                                          ></span
                                        >
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="mb-3"
                              style="border-bottom: 0.5px solid #ffffff66"
                            >
                              <div class="pb-3">Course</div>
                              <div
                                id="show-collapse-mobile"
                                class="collapse show"
                              >
                                <div class="py-1 container">
                                  <div
                                    class="mb-2 row mt-0"
                                    v-for="(mod, mo) in Math.ceil(
                                      getCourses(
                                        $store.state.whiteListedUserData
                                      ).length / 2
                                    )"
                                    :key="mo"
                                  >
                                    <div class="col-6 p-0">
                                      <label class="check-container">
                                        <input
                                          :id="
                                            getCourses(
                                              $store.state.whiteListedUserData
                                            )[mo]
                                          "
                                          type="checkbox"
                                          :checked="
                                            selectedCourses.includes(
                                              getCourses(
                                                $store.state.whiteListedUserData
                                              )[mo]
                                            )
                                          "
                                          @change="
                                            updateCourse(
                                              getCourses(
                                                $store.state.whiteListedUserData
                                              )[mo]
                                            )
                                          "
                                        />
                                        <span
                                          class="checkmark-mobile-dashboard"
                                        ></span>
                                        <span>
                                          <span
                                            class="os-semibold"
                                            style="
                                              text-transform: uppercase !important;
                                            "
                                          >
                                            {{
                                              getCourses(
                                                $store.state.whiteListedUserData
                                              )[mo]
                                            }}
                                          </span>
                                        </span>
                                      </label>
                                    </div>
                                    <div
                                      class="col-6 p-0"
                                      v-if="
                                        mo +
                                          Math.ceil(
                                            getCourses(
                                              $store.state.whiteListedUserData
                                            ).length / 2
                                          ) <
                                        getCourses(
                                          $store.state.whiteListedUserData
                                        ).length
                                      "
                                    >
                                      <label class="check-container">
                                        <input
                                          :id="
                                            getCourses(
                                              $store.state.whiteListedUserData
                                            )[
                                              mo +
                                                Math.ceil(
                                                  getCourses(
                                                    $store.state
                                                      .whiteListedUserData
                                                  ).length / 2
                                                )
                                            ]
                                          "
                                          type="checkbox"
                                          :checked="
                                            selectedCourses.includes(
                                              getCourses(
                                                $store.state.whiteListedUserData
                                              )[
                                                mo +
                                                  Math.ceil(
                                                    getCourses(
                                                      $store.state
                                                        .whiteListedUserData
                                                    ).length / 2
                                                  )
                                              ]
                                            )
                                          "
                                          @change="
                                            updateCourse(
                                              getCourses(
                                                $store.state.whiteListedUserData
                                              )[
                                                mo +
                                                  Math.ceil(
                                                    getCourses(
                                                      $store.state
                                                        .whiteListedUserData
                                                    ).length / 2
                                                  )
                                              ]
                                            )
                                          "
                                        />
                                        <span
                                          class="checkmark-mobile-dashboard"
                                          style="
                                            text-transform: uppercase !important;
                                          "
                                        >
                                        </span>
                                        <span>
                                          <span
                                            class="os-semibold"
                                            style="
                                              text-transform: uppercase !important;
                                            "
                                          >
                                            {{
                                              getCourses(
                                                $store.state.whiteListedUserData
                                              )[
                                                mo +
                                                  Math.ceil(
                                                    getCourses(
                                                      $store.state
                                                        .whiteListedUserData
                                                    ).length / 2
                                                  )
                                              ]
                                            }}
                                          </span>
                                        </span>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex justify-content-center mb-32">
                        <div class="d-flex">
                          <button
                            class="text-center font-weight-bold font-16 reset-button-filter"
                            @click="resetFilters"
                          >
                            Reset Filters
                          </button>
                        </div>
                        <div class="d-flex">
                          <button
                            data-dismiss="modal"
                            class="text-center font-weight-bold font-16 apply-filter-button-mobile"
                          >
                            Apply ({{
                              getAppliedFilters(
                                $store.state.whiteListedUserData
                              )
                            }})
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="cursor-pointer d-flex align-items-center ml-3 md-0"
              style="
                background: #2c2c2cd4;
                width: 99px !important;
                height: 39px !important;
                border-radius: 5px;
                border: 0.3px solid #d0d0d05f;
              "
            >
              <img
                :src="getIcon('funnel-mobile.svg')"
                alt="funnel-mobile"
                class="pr-1 pl-1"
              />
              <select
                name="role"
                id="select-role"
                class="select-role font-14 font-weight-bold"
                style="width: 77px"
                @change="switchSelect($event)"
              >
                <option value="" class="" selected>Role</option>
                <option value="student" class="">Student</option>
                <option value="instructor" class="">Instructor</option>
                <option value="pod operator" class="">Pod Operator</option>
                <option value="admin" class="">Admin</option>
              </select>
              <img
                height="10"
                width="10"
                :src="getIcon('accordion-down-arrow.svg')"
                alt="check-solid-icon"
                style="margin-left: -19px !important"
              />
            </div>
            <div
              class="cursor-pointer d-flex align-items-center px-3 md-0"
              style="margin-left: auto !important"
            >
              <button
                v-if="$store.state.role == 'admin'"
                type="button"
                class="invite-participants mr-5 mr-md-0 pt-8 pt-md-0 pb-8 pb-md-0 pl-16 pl-md-0 pr-16 pr-md-0 mr-md-0 font-14 font-md-16"
                @click="emptyModalData()"
                data-toggle="modal"
                data-target="#invite-participants"
              >
                <span class="font-weight-bold os-bold">Invite users</span>
              </button>
            </div>
          </v-card-title>
          <v-data-table
            v-if="this.$store.state.refresh"
            :page="page"
            :headers="headers"
            :items="filterUsers(this.$store.state.whiteListedUserData)"
            :items-per-page="itemsPerPage"
            :search="search"
            hide-default-footer
            class="text-left font-24 d-none d-md-block"
            style="background: #0e0e0e"
            @update:options="options = $event"
            @pagination="myPagination"
            dark
          >
            <template v-slot:item.status="{ item }">
              <div>
                <template v-if="item.account_created">
                  <img
                    class=""
                    :src="getIcon('yellow-bullet-point.svg')"
                    alt="yellow-bullet-point"
                  />
                  <span> Registered</span>
                </template>

                <template v-else>
                  <img
                    class=""
                    :src="getIcon('not-enrolled-bullet.svg')"
                    alt="not-enrolled-bullet"
                  />
                  <span>
                    Not enrolled
                    <a
                      class="resend-invite ml-2 font-12"
                      @click="resendEmailModal(item)"
                      >Resend invite</a
                    ></span
                  >
                </template>
              </div>
            </template>
            <template v-slot:item.first_name="{ item }">
              <div
                @click="showWhitelistUserInfoModal(item)"
                style="cursor: pointer"
              >
                <template v-if="item.first_name != null">
                  <span>{{ item.first_name }}</span>
                </template>
                <template v-else>
                  <p class="text-left font-20">-</p>
                </template>
              </div>
            </template>
            <template v-slot:item.last_name="{ item }">
              <div
                @click="showWhitelistUserInfoModal(item)"
                style="cursor: pointer"
              >
                <template v-if="item.last_name != null">
                  <span>{{ item.last_name }}</span>
                </template>
                <template v-else>
                  <p class="text-left font-20">-</p>
                </template>
              </div>
            </template>
          </v-data-table>
          <div class="d-flex justify-content-center" v-else>
            <lottie-player
              autoplay
              loop
              mode="normal"
              class="check-in-animation"
              :src="check_in_arrow_json"
              style="width: 100px; height: 100px"
            ></lottie-player>
          </div>
          <table
            class="d-block d-md-none text-light"
            style="background: #252525"
          >
            <tr
              v-for="(user, ui) in currentDetails()"
              :key="ui"
              class="d-flex justify-content-between p-3"
            >
              <div class="text-left" @click="showWhitelistUserInfoModal(user)">
                <p
                  class="mb-0 font-weight-bold name-field"
                  v-if="user.first_name"
                >
                  {{ user.first_name }} {{ user.last_name }}
                </p>
                <p class="mb-0 font-weight-bold name-field font-16" v-else>
                  {{ user.email }}
                </p>
                <p class="mb-0 text-capitalize font-12 font-md-16">
                  {{ user.role }}
                </p>
              </div>
              <div class="d-flex align-items-center font-14 font-md-16">
                <span
                  v-if="user.account_created"
                  class="font-weight-bold"
                  style="color: #ffc627"
                  >Registered</span
                >
                <span v-else class="font-weight-bold">Not enrolled</span>
              </div>
            </tr>
          </table>
          <v-pagination
            class="pt-55 pb-55 table-pagination"
            v-model="page"
            :length="Math.round(pagecount)"
            circle
            dark
            :total-visible="10"
          ></v-pagination>
        </v-card>
      </div>
    </div>
    <div
      class="modal fade px-0"
      id="invite-participants"
      data-backdrop="static"
      data-keyboard="false"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-md mx-auto">
        <div class="modal-content h-100 animate-bottom br-18">
          <div
            class="modal-body p-0 h-100 br-18"
            style="
              border: 0.5px solid #0b5786 !important;
              box-shadow: 0px 3px 14px 0px rgba(184, 236, 255, 0.15);
              border-radius: 12px;
}
            "
          >
            <div class="component container" style="padding: 0px">
              <div class="">
                <template v-if="step == 1">
                  <div class="col-12 right-col p-0">
                    <form action="" style="margin: auto">
                      <div
                        class="modal-header p-0 d-flex align-items-center"
                        style="border-bottom: 0.5px solid #555555 !important"
                      >
                        <span class="col-2"></span>
                        <h5
                          class="modal-title font-md-18 font-16 px-0 text-white col-8 text-center kallisto-bold pb-12p pb-md-20 pt-md-22 pt-14p"
                        >
                          Invite user
                        </h5>
                        <button
                          type="button"
                          class="close m-0 col-2 p-0 pr-20 d-flex justify-content-end"
                          data-dismiss="modal"
                        >
                          <img
                            :src="getIcon('x-button.svg')"
                            alt="x-button"
                            class="x-button"
                          />
                        </button>
                      </div>

                      <div
                        class="text-left mt-24 pb-3 ml-20 mr-20 mb-5"
                        style="border-bottom: 1px solid #555555"
                      >
                        <p class="font-14 font-md-16 mb-0 pb-10p">Email</p>
                        <input
                          v-model="email"
                          type="email"
                          name="email"
                          id="email"
                          required
                          @blur="validateEmail"
                          class="form-control font-md-14 text-light input-instructor text-left w-100 mb-4"
                        />
                        <span
                          v-if="msg.email"
                          class="text-danger font-10 position-absolute"
                          >{{ msg.email }}</span
                        >
                      </div>
                      <div class="form-group mt-3 text-light ml-20 mr-20 mb-2">
                        <p
                          class="font-14 font-md-16 text-left text-light mb-0 pb-10p"
                        >
                          Role
                        </p>
                        <div class="d-flex justify-content-between">
                          <select
                            name="Type"
                            @change="onChangeRole($event)"
                            v-model="role"
                            class="form-control input-instructor font-md-14"
                          >
                            <option disabled selected value>
                              -- select an option --
                            </option>
                            <option class="text-white" value="student">
                              Student
                            </option>
                            <option
                              :disabled="this.$store.state.role != 'admin'"
                              value="instructor"
                            >
                              Instructor
                            </option>
                            <option
                              :disabled="this.$store.state.role != 'admin'"
                              value="admin"
                            >
                              Admin
                            </option>
                            <option
                              :disabled="this.$store.state.role != 'admin'"
                              value="pod operator"
                            >
                              Pod Operator
                            </option>
                          </select>
                          <span class="arrow-styling">
                            <img
                              src="@/assets/icons/chevron-down-solid.svg"
                              alt="select option type"
                              width="10"
                              class="ml-1 hide-hover"
                            />
                          </span>
                        </div>
                      </div>
                      <div
                        class="form-group mt-3 text-light ml-20 mr-20"
                        style="border-top: 1px solid rgb(85, 85, 85)"
                      >
                        <p
                          class="font-14 font-md-16 text-left text-light mb-0 pb-10p mt-10"
                        >
                          Location
                        </p>

                        <div class="d-flex justify-content-between">
                          <div class="dropdown-container mb-1">
                            <div
                              class="dropdown-header"
                              @click="toggleDropdown"
                            >
                              <span>{{ selectedOptionsText }}</span>
                            </div>
                            <div
                              class="dropdown-menuDashboard mt-2"
                              v-if="isOpen"
                            >
                              <label
                                v-for="(site, st) in $store.state.allSites"
                                :key="st"
                                class="dropdown-item p-0"
                              >
                                <label
                                  class="container text-left mb-0"
                                  style="padding: 8px !important"
                                >
                                  <input
                                    type="checkbox"
                                    :value="site.site_id"
                                    v-model="selectedOptions"
                                    :checked="
                                      selectedOptionsText.includes(
                                        site.site_name
                                      )
                                        ? 'checked'
                                        : ''
                                    "
                                  />
                                  <span class="checkmark"></span>
                                  &emsp; {{ site.site_name }}
                                </label>
                              </label>
                            </div>
                            <span
                              class="arrow-styling"
                              style="position: absolute; top: 0; right: 0%"
                            >
                              <img
                                src="@/assets/icons/chevron-down-solid.svg"
                                alt="select option type"
                                width="10"
                                class="ml-1 hide-hover"
                              />
                            </span>
                          </div>
                        </div>
                        <div class="mt-3" style="border-top: 1px solid #555555">
                          <button
                            type="button"
                            :class="
                              !(this.email && this.role && !msg.email)
                                ? 'invite-button-disabled mx-auto d-block cus-btn mt-130 mb-40'
                                : 'invite-button mx-auto d-block cus-btn mt-130 mb-40'
                            "
                            @click="inviteParticipants(1)"
                            :disabled="!(this.email && this.role && !msg.email)"
                          >
                            <span class="font-weight-bold apply">Next</span>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade px-0"
      id="invite-success"
      data-backdrop="exampleModalLabel"
      data-keyboard="false"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-md mx-1 mx-md-auto">
        <div
          class="modal-content animate-bottom"
          style="
            border: 0.5px solid #0b5786 !important;
            box-shadow: 0px 3px 14px 0px rgba(184, 236, 255, 0.15);
            border-radius: 12px;
            background-color: #0e0e0e !important;
          "
        >
          <div
            class="p-0 modal-body"
            style="width: 514px !important; height: 282px !important"
          >
            <div class="col-12 right-col p-0">
              <div class="">
                <div class="mt-24 mt-md-55">
                  <div class="d-inline-block">
                    <div class="yellow-check-div text-center">
                      <img
                        class="yellow-check"
                        :src="getIcon('yellow-check-solid.svg')"
                        alt="yellow-check"
                      />
                    </div>
                  </div>
                  <div class="form-group mt-1 mt-md-2 text-center">
                    <p
                      class="text-center font-16 font-md-22 os-regular d-inline-block mb-0"
                    >
                      Invite has been sent
                    </p>
                  </div>
                </div>
              </div>
              <div class="text-center">
                <div
                  class="d-flex justify-content-center ml-20 mr-20 mb-24 mb-md-32 mt-4"
                >
                  <button
                    type="button"
                    class="invite-button invite-mobile-button cursor-pointer mx-2"
                    @click="emptyModalData(true)"
                    data-dismiss="modal"
                    data-toggle="modal"
                  >
                    <span class="font-weight-bold font-14 font-md-16">Ok</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade px-0"
      id="invite-resend"
      data-backdrop="exampleModalLabel"
      data-keyboard="false"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-md mx-1 mx-md-auto">
        <div
          class="modal-content animate-bottom"
          style="
            border: 0.5px solid #0b5786;
            border-radius: 18px;
            box-shadow: 0px 3px 14px 0px rgba(184, 236, 255, 0.15);
          "
        >
          <div
            v-if="resendstep == 1"
            class="pl-32 pr-32 pt-32 pb-32 modal-body"
            style=""
          >
            <p class="text-left mb-55">
              <a class="font-20 kallisto-bold">Resend invite</a>
            </p>
            <p class="font-22 os-regular mb-1">
              Do you want to resend invitation to:
            </p>
            <p class="font-18 os-regular mb-40">
              <span v-if="resendModal.first_name">{{
                resendModal.first_name
              }}</span>
              <span v-if="resendModal.last_name">{{
                resendModal.last_name
              }}</span>
              <span v-else>{{ resendModal.email }}</span>
            </p>
            <div class="d-flex justify-content-center">
              <button
                class="invite-button-border mx-2 os-semibold"
                data-dismiss="modal"
                data-toggle="modal"
              >
                Cancel
              </button>
              <button
                @click="resendEmail(resendModal)"
                class="invite-button mx-2 os-bold"
              >
                Resend
              </button>
            </div>
          </div>
          <div
            v-else-if="resendstep == 2"
            class="pl-32 pr-32 pt-32 pb-32 modal-body"
            style=""
          >
            <img
              :src="getIcon('check-solid.svg')"
              alt="checked"
              class="checkin-complete mt-40 mb-10"
            />
            <p class="font-22 text-center mb-40">Invite successfully sent.</p>
            <button
              data-dismiss="modal"
              data-toggle="modal"
              class="invite-button os-bold"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="flag">
      <WhitelistUserInfoModal
        :userInfo="selectedWhiteListedUser"
        @backButtonClicked="loadTable()"
      />
    </div>
  </div>
</template>
<script>
import { API } from "aws-amplify";
import WhitelistUserInfoModal from "@/components/instructor/WhitelistUserInfoModal.vue";
import check_in_arrow_animation from "@/assets/icons/loader.json";
import $ from "jquery";

export default {
  components: {
    WhitelistUserInfoModal,
  },
  data() {
    return {
      check_in_arrow_json: JSON.stringify(check_in_arrow_animation),
      selectedWhiteListedUser: {},
      flag: false,
      isUserDataLoaded: false,
      email: "",
      msg: [],
      isOpen: false,
      selectedOptions: [],
      role: "",
      location: "",
      firstName: "",
      lastName: "",
      step: 1,
      resendstep: 1,
      search: "",
      headers: [
        {
          text: "First name",
          align: "start",
          value: "first_name",
        },
        {
          text: "Last name",
          align: "start",
          value: "last_name",
        },
        { text: "Email", value: "email" },
        { text: "Status", value: "status" },
      ],
      tableData: [],
      filteredData: [],
      pagecount: 0,
      page: 1,
      itemsPerPage: 10,
      options: {
        pageCount: 1,
      },
      selectedrole: "",
      selectedSemesters: [],
      selectedCourses: [],
      resendModal: {},
      apiIndex: 0,
    };
  },
  watch: {
    email(value) {
      this.validateEmail(value);
    },
    selectedOptions() {
      this.onChangeLocation();
    },
  },
  computed: {
    selectedOptionsText() {
      if (this.selectedOptions.length === 0) {
        return "-- select an option --";
      }
      // Join selected site names into a comma-separated list
      return this.selectedOptions
        .map((id) => {
          const site = this.$store.state.allSites.find(
            (site) => site.site_id === id
          );
          return site ? site.site_name : "";
        })
        .join(", ");
    },
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    onChangeLocation() {
      // Handle changes when checkboxes are selected/deselected
      // For example, you can emit an event or update the store
      this.$emit("update:selectedOptions", this.selectedOptions);
    },
    getSemesters(usersData) {
      let semesters = new Set();
      usersData.map((user) => {
        if (user.roster) {
          user.roster.map((userRosterData) => {
            if (userRosterData.semester) semesters.add(userRosterData.semester);
          });
        }
      });
      return [...semesters];
    },
    getCourses(usersData) {
      let courses = new Set();
      usersData.map((user) => {
        if (user.roster) {
          user.roster.map((userRosterData) => {
            if (userRosterData.course) courses.add(userRosterData.course);
          });
        }
      });
      return [...courses];
    },
    resendEmailModal(item) {
      $("#invite-resend").modal();
      this.resendstep = 1;
      this.resendModal = item;
    },
    validateEmail(value) {
      //eslint-disable-next-line
      if (
        /^([a-zA-Z0-9_\-\\.]+)@([a-zA-Z0-9_\\-]+)(\.[a-zA-Z]{2,5}){1,2}$/.test(
          value
        )
      ) {
        this.msg["email"] = false;
      } else {
        this.msg["email"] = "Invalid Email Address";
      }
    },
    switchSelect(event) {
      this.selectedrole = event.target.value;
    },
    uncheckAll() {
      const checkboxes = document.querySelectorAll(
        '.check-container input[type="checkbox"]'
      );
      checkboxes.forEach((checkbox) => {
        checkbox.checked = false;
      });
    },
    resetFilters() {
      this.selectedSemesters = [];
      this.selectedCourses = [];
      this.RF();
    },
    filterByRole(data) {
      if (this.selectedrole != "") {
        return data.filter((a) => {
          if (a.role == this.selectedrole) {
            return a;
          }
        });
      }
      return data;
    },
    filterUsers(data) {
      // Filter by Role
      if (this.selectedrole != "") {
        data = data.filter((user) => user.role == this.selectedrole);
      }

      if (this.selectedSemesters.length > 0) {
        // Filter by Semester
        data = data.filter(
          (user) =>
            user.roster &&
            user.roster.find((courseDetail) =>
              this.selectedSemesters.includes(courseDetail.semester)
            )
        );
      }
      if (this.selectedCourses.length > 0) {
        // Filter by Course
        data = data.filter(
          (user) =>
            user.roster &&
            user.roster.find((courseDetail) =>
              this.selectedCourses.includes(courseDetail.course)
            )
        );
      }
      return data;
    },
    updateSemester(value) {
      if (this.selectedSemesters.includes(value)) {
        this.selectedSemesters = this.selectedSemesters.filter(
          (e) => e !== value
        );
      } else {
        this.selectedSemesters.push(value);
      }
      this.RF();
    },
    updateCourse(value) {
      if (this.selectedCourses.includes(value)) {
        this.selectedCourses = this.selectedCourses.filter((e) => e !== value);
      } else {
        this.selectedCourses.push(value);
      }
      this.RF();
    },
    RF() {
      this.$store.state.refresh = false;
      setTimeout(() => {
        this.$store.state.refresh = true;
      }, 100);
    },
    getAppliedFilters(usersData) {
      return this.selectedSemesters.length + this.selectedCourses.length;
    },
    currentDetails() {
      var mainArray;
      const now = this;
      if (this.search == "" || this.search == null) {
        mainArray = this.$store.state.whiteListedUserData;
      } else {
        mainArray = this.$store.state.whiteListedUserData.filter((a) => {
          if (a.email.toLowerCase().includes(now.search.toLowerCase())) {
            return a;
          }
        });
      }
      const perChunk = 10; // items per chunk
      const result = mainArray.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / perChunk);

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = []; // start a new chunk
        }

        resultArray[chunkIndex].push(item);

        return resultArray;
      }, []);
      return result[this.page - 1];
    },
    myPagination(table) {
      this.pagecount = (table.itemsLength + 4) / 10;
    },
    async resendEmail(item) {
      let user_details = {
        body: {
          email: item.email,
          first_name: item.first_name,
        },
      };

      await this.$store
        .dispatch("resendEmail", user_details)
        .then((response) => {
          if (response) {
            if (response.message) {
              this.resendstep = 2;
            } else {
              this.$toasted.show(`Error occured: ${response}`, {
                class: ["toasting"],
                position: "top-right",
                duration: 5000,
              });
            }
          }
        });
    },
    showWhitelistUserInfoModal(item) {
      this.$store.state.loader = true;
      this.apiIndex = 0;
      this.selectedWhiteListedUser = item;
      this.$store.commit("CLEAR_USER_DATA");
      // Show the modal after API call completes
      this.$nextTick(async () => {
        // Trigger API calls
        let dataTerms = {
          userId: item.user_id,
          index: 0,
        };
        await this.reccursiveUserData(dataTerms);

        // Show the modal only after API calls are completed
        $("#wluModal").modal("show");
        this.$store.state.loader = false;
      });
    },
    async reccursiveUserData(dataTerms) {
      try {
        const response = await this.$store.dispatch("getUserData", dataTerms);
        await this.$store.dispatch("getWhiteListUserData", dataTerms);

        if (response.length === 25) {
          dataTerms.index = dataTerms.index + 1;
          await this.reccursiveUserData(dataTerms);
        }
        this.flag = true; // Ensure this is set when done
      } catch (error) {
        console.error("Error in recursive user data fetch", error);
        this.flag = false; // Handle error state
      }
    },
    getImage(img) {
      return require("@/assets/avatars/" + img);
    },
    getIcon(img) {
      return require("@/assets/icons/" + img);
    },

    inviteParticipants() {
      //MAKE API CALL TO CHANDI'S PROXY;
      let now = this;
      var inviteUser = {
        body: {
          email: this.email,
          role: this.role,
          experience_data: {
            customization: {
              mean_value: 50,
            },
            // location : ""
          },
        },
      };

      API.put(
        "proxy",
        `/${this.$store.state.selected_site_id}/dsl/access/user`,
        inviteUser
      )
        .then(() => {
          this.step = 2;
          $("#invite-participants").modal("hide");
          $("#invite-success").modal();
          API.get(
            "proxy",
            `/${this.$store.state.selected_site_id}/dsl/access/users/`
          )
            .then((response) => {
              now.tableData = now.filteredData = response;
              now.pagecount = (now.filteredData.length + 4) / 10;
            })
            .catch((err) => {
              window.console.log("Error occured", err);
            });
        })
        .catch((err) => {
          this.$toasted.show(`Error: ${err.response.data.message}`, {
            position: "top-right",
            duration: 5000,
          });
        });
    },
    onChangeRole(event) {
      this.role = event.target.value;
      this.validateEmail(this.email);
    },
    emptyModalData(closeModal) {
      this.email = "";
      this.role = "";
      if (closeModal) {
        $("#invite-success").modal("hide");
      }

      this.step = 1;
    },
    async loadTable() {
      await this.$store.dispatch("setWhiteListUsers").then(() => {
        this.isUserDataLoaded = true;
        this.tableData = this.filteredData =
          this.$store.state.whiteListedUserData;
        this.$store.commit("REFRESH");
      });
      $("#pod-check-in").on("hide.bs.modal", function () {});
      let now = this;
      $("#wluModal").on("hidden.bs.modal", function () {
        // refresh table data
        API.get(
          "proxy",
          `/${this.$store.state.selected_site_id}/dsl/access/users/`
        )
          .then((response) => {
            now.tableData = now.filteredData = response;
            now.pagecount = (now.filteredData.length + 4) / 10;
          })
          .catch((err) => {
            window.console.log("Error occured", err);
          });
      });

      API.get(
        "proxy",
        `/${this.$store.state.selected_site_id}/dsl/access/users/`
      )
        .then((response) => {
          this.tableData =
            this.filteredData =
            this.$store.state.whiteListedUserData =
              response;
          this.pagecount = (this.filteredData.length + 4) / 10;
        })
        .catch((err) => {
          window.console.log("Error occured", err);
        });
    },
  },
  async mounted() {
    await this.loadTable();
  },
};
</script>
<style lang="scss">
.modal input {
  height: auto !important;
}
.dropdown-container {
  position: relative;
  display: inline-block;
}

.dropdown-header {
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #2c2c2c !important;
  padding: 10px;
  width: 479px;
  border-radius: 6px;
}

.dropdown-menuDashboard {
  padding: 0px !important;
  position: absolute;
  background-color: #fff;
  // border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  z-index: 1000;
}

.dropdown-item {
  color: white;
  background: #2c2c2c !important;
  display: flex;
  font-size: 14px;
  align-items: center;
  padding-left: 3px;
  padding-bottom: 0px;
  margin-bottom: 0 !important;
}
.dropdown-item:hover {
  color: white !important;
}
.dropdown-item input {
  margin-right: 8px;
}

.arrow-styling {
  margin-left: 8px;
}

.dropdown-menuDashboard {
  display: none;
}

.dropdown-container .dropdown-menuDashboard {
  display: block;
}
.arrow-styling {
  background-color: #2d2d2d !important;
  padding-top: 9px;
  padding-bottom: 4px;
  padding-right: 20px;
  margin-left: -20px;
  border-radius: 0 6px 6px 0;
}
.resend-invite {
  background: #2e2e2e;
  border-radius: 4px;
  padding: 6px 12px;
  color: white !important;
  &:hover {
    background: #5d5d5d !important;
  }
}
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none !important;
}
.select-role {
  background-color: #282828 !important;
}

.name-field {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 175px;
}
.instructor-bg {
  height: 209px;
  background: linear-gradient(0deg, #151515 1%, transparent 50%),
    url("../../assets/images/insttructor.png");
  background-size: cover;
  @media only screen and (max-width: 676px) {
    height: 90px;
  }
}
#search-field::placeholder {
  color: #d0d0d0;
  opacity: 1; /* Firefox */
}
#search-field:focus {
  background-color: transparent;
  outline: none;
}
table {
  border-collapse: collapse;
}

.theme--dark.v-pagination .v-pagination__item {
  background: #343434 !important;
  color: #ffffff !important;
  font-weight: bold;
}
.theme--dark.v-pagination .v-pagination__item--active {
  background: #ffc627 !important;
  color: #000000 !important;
}
.v-input__icon {
  cursor: pointer !important;
}
.v-data-table__mobile-table-row {
  .v-data-table__mobile-row:last-child {
    border-bottom: 1px solid;
  }
}
.v-data-table__mobile-row {
  max-width: 100vw;
  .v-data-table__mobile-row__cell {
    max-width: 60vw;
  }
}
thead.v-data-table-header.v-data-table-header-mobile {
  display: none;
}
.instructor-dashboard {
  height: 100%;
  background-color: #0e0e0e;
  .apply-button,
  .invite-participants {
    border-radius: 7px !important;

    span {
      letter-spacing: -0.08px;
      display: inline-block;
    }
  }

  .invite-button {
    border: 1px solid #ffc627 !important;
    color: #151515 !important;
    font-size: 16px !important;
    border-radius: 10px;
    background-color: #ffc627;
    width: 136px;
    height: 42px;
  }
  .invite-button-border {
    border: 1px solid #ececec !important;
    font-size: 16px !important;
    border-radius: 10px;
    background-color: #2c2c2c !important;
    width: 136px;
    height: 42px;
  }

  .invite-button-disabled {
    border: 1px solid #707070 !important;
    color: #707070 !important;
    font-size: 16px !important;
    border-radius: 10px !important;
    background-color: #151515;
    width: 136px;
    height: 42px;
  }

  .modal-dialog {
    height: 602px !important;
    .left-image {
      max-height: 602px !important;
    }

    .invite {
      border: 1px solid #ffffff !important;
      color: #ffffff !important;
      font-size: 16px !important;
      border-radius: 10px !important;
      background-color: #0e0e0e;
      span {
        padding: 12px 32px;
        letter-spacing: -0.08px;
        display: inline-block;
      }
    }
    .yellow-check-div {
      width: 48px;
      height: 48px;
      background: #ffc627;
      border-radius: 50%;
      position: relative;

      .yellow-check {
        width: 60%;
        position: absolute;
        top: 30%;
        left: 22%;
      }
    }
  }
  @media only screen and (max-width: 676px) {
    .invite-button,
    .apply-button,
    .invite {
      border-radius: 6px !important;
      color: #000000 !important;
      background: #ffc627;
      width: 262px;
      font-size: 16px !important;
      height: 38px;
    }
    .apply-button {
      padding: 0px !important;
    }
    .invite-mobile-button,
    .invite-mobile-button-disabled {
      width: 106px !important;
    }
    .invite-button-disabled {
      border-radius: 6px !important;
      width: 262px;
      font-size: 16px !important;
      height: 38px;
    }
    .invite-participants {
      margin-top: 20px;
      border-radius: 6px !important;
      padding: 10px 20px;
    }
    @keyframes animatebottom {
      from {
        bottom: -300px;
        opacity: 0;
      }

      to {
        bottom: 0;
        opacity: 1;
      }
    }
    .modal-dialog {
      max-width: 100%;
    }
  }

  @media only screen and (max-width: 992px) {
    .table-pagination {
      overflow: scroll;
    }
    .table-pagination::-webkit-scrollbar {
      display: none;
    }
    .table-pagination {
      -ms-overflow-style: none;
      scrollbar-width: none; /* Firefox */
    }
    .modal-dialog {
      height: 602px !important;
    }

    .apply-button {
      margin-top: 70px;
    }
  }
}
.input-instructor {
  background-color: #2d2d2d !important;
  border: none !important;
}
.label-instructor {
  float: left;
}
select:focus {
  background-color: #0e0e0e;
}
select {
  background-color: #0e0e0e !important;
  color: #ffffff !important;
  option {
    color: white !important;
  }
}
.group-1 {
  background-color: #ffc627;
  width: 85px;
  height: 85px;
  color: black;
  border-radius: 8px 0px 0px 8px;
}
.group-2 {
  background-color: #ffc62799;
  width: 85px;
  height: 85px;
  color: black;
}
.group-3 {
  background-color: #ffffff96;
  width: 85px;
  height: 85px;
  color: black;
  border-radius: 0px 8px 8px 0px;
}

hr {
  background-color: grey !important;
  height: 0.5px;
  opacity: 0.3;
}

.instructor-data-table {
  tr,
  td {
    border-bottom: 1px solid #93751f80 !important;
  }
  .theme--light.v-data-table .v-data-table__empty-wrapper {
    color: white;
  }
  .v-card__title {
    background-color: #0e0e0e;
    .v-input {
      max-width: 255px;
      align-content: center;
      padding: 0;
      margin: 0;
    }

    .v-input input:active,
    .v-input input:focus,
    .v-input textarea:active,
    .v-input textarea:focus {
      background: white !important;
    }
    .v-input__slot {
      background: white !important;
      border-radius: 3px;
      border: 1px solid #999999;
      .mdi-magnify {
        color: #2a2a2a;
      }
      .theme--light.v-label {
        padding-left: 9px;
      }
      #input-61 {
        padding-left: 9px;
      }
    }
  }
  .v-data-table-header {
    background: #0e0e0e 0% 0% no-repeat padding-box !important;
    border-radius: 4px;
    colgroup {
      background: transparent;
    }
    span {
      color: white !important;
      font-size: 14px;
    }
  }
  tbody {
    background-color: #0e0e0e !important;
    td.text-start {
      color: white !important;
      max-width: 250px;
    }
  }
  .v-data-footer {
    background: #151515 !important;
    color: white !important;

    .theme--light.v-select .v-select__selection--comma {
      color: white !important;
    }

    i {
      color: white;
    }
    .v-btn__content {
      color: white;
    }
  }
  .send-again {
    text-decoration: underline;
    color: #ffc627;
  }

  .toast-container {
    .bg-success {
      background-color: #ffc627 !important;
    }
  }

  div.progress.rounded-0 {
    .bg-success {
      background: #ffc627 !important;
    }
  }

  tbody {
    tr:hover {
      background-color: #1f1f1f !important;
    }
  }

  .v-card > :last-child:not(.v-btn):not(.v-chip) {
    color: white;
  }

  .toasting {
    color: yellow !important;
    background-color: pink !important;
  }

  .v-data-table-header-mobile {
    th {
      background-color: white !important;
      color: white !important;
    }
  }
  a {
    cursor: pointer;
  }
}
.select-dropdown-icon {
  position: relative;
  right: 3%;
}
.animate-bottom {
  position: relative;
  animation: animatebottom 0.4s;
}

.text-gold {
  color: #ffc627;
}
@media only screen and (max-width: 676px) {
  .modal-dialog {
    border-top: 0.5px #ffc627;
    border-radius: 8px;
  }
}
@media only screen and (min-width: 768px) {
  #invite-participants {
    .modal-content {
      min-width: 522px !important;
    }
  }
  .invite-participants {
    height: 39px !important;
    width: 118px !important;
  }
}

// *********
.modal-md {
  width: 75%;
}
.modal-backdrop {
  z-index: 990 !important;
}

.filter-button-mobile {
  width: 77px;
  height: 32px;
  background-color: #282828 !important;
  color: #ffffff !important;
  border: 0.3px solid #d0d0d026 !important;
  border-radius: 5px !important;
}
.filter-header-mobile {
  font-size: 18px;
  color: #ffc627;
}
.apply-filter-button-mobile {
  width: 136px !important;
  height: 42px !important;
  background-color: #ffc627 !important;
  color: #000;
  border: 0.5px solid #ffc627 !important;
  border-radius: 8px !important;
}
.clear-filter-button-mobile {
  width: 136px !important;
  height: 42px !important;
  background-color: #151515 !important;
  color: #ffffff;
  border: 0.5px solid #ffffff !important;
  border-radius: 8px !important;
}
@media only screen and (max-width: 767px) {
  .clear-filter-button-mobile,
  .apply-filter-button-mobile {
    height: 35px !important;
    width: 106px !important;
  }
}
/* The container */
.check-container {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.check-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #eee;
  border-radius: 4px;
}

.checkmark-mobile-dashboard {
  position: absolute;
  top: 7px !important;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #151515;
  border: 1px solid #0e4f74;
  border-radius: 3px;
}

/* On mouse-over, add a grey background color */
.check-container:hover input ~ .checkmark {
  background-color: #ccc;
}

.check-container:hover input ~ .checkmark-mobile-dashboard {
  background-color: #3c3c3c;
}

/* When the checkbox is checked, add a blue background */
.check-container input:checked ~ .checkmark {
  background-color: #ffc627;
  border-radius: 4px;
}

.check-container input:checked ~ .checkmark-mobile-dashboard {
  background-color: #ffc627;
  border-radius: 4px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkmark-mobile-dashboard:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check-container input:checked ~ .checkmark:after {
  display: block;
}

.check-container input:checked ~ .checkmark-mobile-dashboard:after {
  display: block;
}

/* Style the checkmark/indicator */
.check-container .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid #000;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
}
.check-container .checkmark-mobile-dashboard:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid #000;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
}

.check-btn {
  background-color: #ffffff !important;
  border-radius: 7px !important;
  box-shadow: 0px 3px 6px #00000081 !important;
}
.import-btn {
  padding: 12px 29px !important;
  border-radius: 30px !important;
}
.pod-num {
  background: #0e4f74;
  color: #ffffff;
  border-radius: 50%;
  padding: 3px 4px;
  font-size: 14px;
}
a:hover {
  text-decoration: none !important;
}
.reset-button-filter {
  background: #313131 !important;
  color: #f1f1f1;
  border-radius: 8px;
  padding-right: 28px;
  padding-left: 28px;
  margin-right: 16px;
}
.modal.modal-content {
  background-color: #0e0e0e !important;
  width: 514px;
  height: 282px;
}
.uppercase-text span {
  text-transform: uppercase !important;
}
</style>
<style scoped>
/* The container */
.container {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  top: 30%;
  left: 12px;
  height: 18px;
  width: 18px;
  background-color: #2c2c2c;
  border: 1px solid #0e4f74;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #2c2c2c;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #007dc5;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid black;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
